<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger)

export default {
  mounted() {

    document.querySelectorAll(".signature, .marker").forEach((el, index) => {
        ScrollTrigger.create({
            trigger: el,
            id: index+1,
            start: 'top 50%',
            toggleClass: {targets: el, className: "appear"},
            once: true,
        });
    });

  },
};
</script>

<template>
    <section class="company">
    
    <figure><v-img :src="require('../assets/staffs_add.jpg')"/><figcaption>ABOUT</figcaption></figure>
    <h4>私たちにお任せください</h4>
    <p>と言いたいところですが、<br>まずはスモールスタートです。<!-- <span>※法人成り予定</span> --></p>
    <p><span class="marker">専属の担当者を雇う余裕がない</span><span class="marker">本来の業務に集中したい</span><span class="marker">今の制作会社に不満がある</span></p>
    <p>きっかけは何でも結構です。<br>まずはお気軽にお問い合わせください。</p>
    <table>
      <tr>
        <th>屋号</th>
        <td>_01d（アンダーバーオールド）</td>
      </tr>
      <tr>
        <th>住所</th>
        <td>〒730-0051 広島県広島市中区大手町1-1-20 相生橋ビル7階 A号室</td>
      </tr>
      <tr>
        <th>代表者</th>
        <td><div class="signature"><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 316 48.8" style="enable-background:new 0 0 316 48.8;" xml:space="preserve">
			<g id="レイヤー_1" class="st0">
			</g>
			<g id="signature">
				<path class="st1" d="M14.6,7.6c0,0-4.4-1.9-1-4.8S20.5,1.6,24,2.1s6.4,1,8.6,1.1s5-0.4,6.5-1.3c-4.5,1.5-3.9,1.9-11.4,1.5
					c-1,2.3-9.5,24.5-10.9,26.4s-4.1,3.4-8.4,2.1s-6.8-4.9-6.8-4.9s6.5,2.5,10.5,2.5s7.9-0.4,11.5-1.5s6.9-6,6.9-6s2.4-3.1,3.1-3.5
					c-2.3,2.3-5.5,6-5.8,7.8s-0.6,2.6,0.6,4.5s2.5,1.3,4.5,0.6s3.1-2.4,4.1-3.9s2.5-4.1,2.8-5.8s0.4-3.8-1.4-4.4s-2.9,1.9-3.3,2.6
					s-1.1,3.8-0.9,5.3s1.6,3.5,3.3,3.9s4.1-1.5,5.3-2.3s5.5-4.9,6.9-6.1s3.1-3.9,4.1-3s0.9,2,0.8,4s-4.4,9.8-4.4,9.8
					c2.1-3.3,6.4-10.8,7.8-12s3.8-3.3,4.6-1.6s0.8,3.9,0.6,5s-1.8,4.4-2.1,5.8S60,32,60,32s4.1-8.8,6-10.6s4.4-3.9,6.1-3.5
					s2,2.1,0.9,4.5s-2.3,5.3-2.6,6.6s-1,3.1,0.6,3.3s4.1-2.4,5.3-3.5s3-3,5.1-5.5s5-5,6.1-5.3c-2.4,2-4.3,4-5.1,5.5s-1.8,4.6-1.1,6.6
					s2,2.3,3.8,2.4s4.1-2.1,5.5-4s3.1-5.4,3.3-7.4s-0.3-4.3-2.8-3.4s-2.9,3.8-3.1,5.4S87.8,27,88.6,28s1.9,1.8,5,0.8s4.4-2.8,6.1-4.6
					s4.1-5.1,5.5-5.6s2.8-1.5,3,0.8s-1.8,5.9-2.3,7.8s-2.3,5.9,0.9,4.8s6-4.5,7.8-6.5s3.4-5.1,3.4-5.1c-0.9,2.5-5.1,12.5-6.1,15.5
					s-3.1,8.4-4.6,9.9s-4.9,2.9-5.4,0.6s0.1-5.4,2.9-7.1s8.8-6,10.9-7s9.8-7.5,10.5-8.4s5.1-5.4,5.8-5.6c-4.3,4.1-4.9,4.4-5.8,7.9
					s-0.3,6,1.4,6.4s4.1-0.8,6.5-3.4s3.9-6,3.9-8.4s-1.6-4.6-3.4-2.4s-2.6,3.8-1.8,8.8c0.5,1.6,3.1,5.8,7.5,2.6c3.8-3,7.4-7.5,8.6-9.3
					s2-2.9,2-2.9c-0.8,1.3-1.3,2.8-1.3,4.5s1.4,4.6,0.6,7.4s-3,4.4-7.4,2.3c-1.8-1.3-2-1.8-2-1.8c3.6,2.6,4,3.6,8.3,2.3
					s9.3-6.1,10.9-7.8s7.5-7.3,10-10.4s6-7.3,6.4-9.3s-0.3-4.9-3.1-2.6c-1.6,1.5-5,9.4-6,11.9S160,31.6,160,31.6
					c1.6-3.5,5.1-9.5,6.8-11s3.1-3.3,5.5-3.1s2.1,2.4,1.5,3.9s-2.5,5.6-3,7.5s-0.8,4,1.4,3s5-3,9.1-7.3s5.6-6.1,5.6-6.1
					c-2,3.3-3.1,6-3.8,8s-1.1,3.4-0.9,4.6s0.9,1.6,2.3,0.6s5.4-5,5.8-5.5s1.4-1.4,1.4-1.4"/>
				<path class="st2" d="M190.3,13.1c0,0,1,0.1,1-0.9s-0.4-1-0.9-0.6S189.6,12.6,190.3,13.1z"/>
				<path class="st3" d="M223,32.8c0,0-3-1.3-3-4.6s0-11.6,2.8-17.6s5.6-11.3,8-9.5s0.8,7.3-0.3,10s-4.3,10.5-8.3,15.1
					c-3.5,4-6.9,6.3-9.5,6.5s-5.5-0.6-6-3.5c-0.1-2.4,0.3-3.5,0.8-3.9c1.6,3,18.5,10.5,22.3,6.1s5.6-6,7.5-8.9s3.3-5.5,4.3-8
					s2.1-5,2.1-5c-1.8,3.9-4.8,10.6-5.4,12.9s-1.3,5-1.3,6.8s0.1,2.8,1.4,3.1s4.6-2.1,5.9-3.6s7.8-8.6,9.5-9.4s4.8-2.8,8.3-1.5
					c-1.3-0.6-7.3-0.5-10.6,4.6s-3.1,6.8-2.4,8.4s2.8,1.9,4.4,1s3.9-2.6,4.8-3.9s4.5-8.1,4.9-10.1c-1.9,4.6-4.5,8.9-4.8,11
					s-0.1,2.4,0.8,3s2.3,0.5,3.4-0.5s6.5-6,9.1-8.5s4.8-4.9,5.9-4.9S280,18,279,21s-4.5,10.5-4.5,10.5c1.4-2.5,4.5-8.5,7-10.6
					s3.9-3.4,5.9-3.1s2.1,2.1,1,4.1s-3.3,6-3.4,8.4s0.6,1.9,1.9,1.6s4.3-3.1,6-4.9s6-6.3,7-7.1s2.4-1.5,2.4-1.5s-4.6,3.6-5.6,6.9
					s-0.8,6.6,1.9,7.1c2.6,0.1,4.1-1,4.9-1.9s3.3-2.4,4.8-7.5s-1.3-6.8-2.8-4.9s-2.4,3.1-2.3,6.8s2.1,4.6,4,4.1s5-2.5,5.9-4.4"/>
				<line class="st4" x1="236.7" y1="13.5" x2="314.3" y2="13.5"/>
			</g>
			</svg></div></td>
      </tr>
      <tr>
        <th>事業内容</th>
        <td>Webサイトの企画・制作・運営<br>
        Webサイトのコンサルティング<br>
        メディアの企画・制作・運営<br>
        マーケティングコンサルティング</td>
      </tr>
      <tr>
        <th>お問い合わせ</th>
        <td><router-link to="/contact">こちらから<v-icon icon="mdi-email-outline" /></router-link></td>
      </tr>
    </table>
  </section>
</template>

<style lang="scss" scoped>
.company {
  position: relative;
  z-index: 1;
  padding: 8% 4% 8%;
  text-align: center;
  figure {
    text-align: center;
    padding:0 6%;
    position:relative;
    overflow: hidden;
    figcaption {
      position: absolute;
      color: #fff;
      top: 50%;
      left: 50%;
      font-family: 'Montserrat', sans-serif;
      font-size: unquote("clamp(3rem, 0.091rem + 7.27vw, 5rem)");
      transform: translate(-50%, -50%);
      mix-blend-mode: exclusion;
    }
  }
  h4 {
    font-size: unquote("clamp(1.125rem, 0.034rem + 2.73vw, 1.875rem)");
    padding-top: 4%;
  }
  @keyframes underAnime {
    0% {
        width: 0;
    }

    100% {
        width: 100%;
    }
  }
  p {
    font-size: unquote("clamp(1rem, 0.818rem + 0.45vw, 1.125rem)");
    padding-top: 1%;
    .marker {
      position: relative;
      &.appear {
        margin-right: 20px;
        &::before {
          content: "";
          width: 0;
          height: 30px;
          display: block;
          background: linear-gradient(transparent 60%,#bbbaba 60%);
          position: absolute;
          top: -7px;
          left: 0;
          z-index: -2;
          animation: underAnime 1s forwards ease-out;
          opacity: 0.7;
        }
        &:nth-of-type(2) {
          &::before {
            background: linear-gradient(transparent 60%,#85ae84 60%);
            animation: underAnime 1s forwards 1s ease-out;
          }
        }
        &:nth-of-type(3) {
          margin-right: 0;
          &::before {
            background: linear-gradient(transparent 60%,#c8b165 60%);
            animation: underAnime 1s forwards 2s ease-out;
          }
        }
      }
    }
    br {
      display: none;
    }
  }
  table {
    max-width: 1000px;
    width: 90%;
    margin: 4% auto;
    tr {
      th {
        text-align: left;
        width: 30%;
        border-bottom: 1px solid #dedede;
        box-sizing: border-box;
        padding: 26px 14px;
      }
      td {
        text-align: left;
        width: 70%;
        border-bottom: 1px solid #dedede;
        box-sizing: border-box;
        padding: 26px 14px;
        a {
          color: #000;
          &:hover {
            text-decoration: none;
          }
        }
      }
      &:first-of-type {
        th,td {
          border-top: 1px solid #dedede;
        }
      }
    }
  }
  @media (max-width: 700px) {
    p {
      .marker {
        display: inline-block;
      }
    }
    figure {
      padding: 0;
      ::v-deep div {
        justify-content: flex-end;
        flex-direction: row-reverse;
        img {
        width: 200% !important;
        height: auto !important;
        left: auto;
        right: 0;
        top: auto;
        bottom: 0;
      }
      }
      
    }
    table {
      width: 100%;
    }
  }
}
@media (max-width: 640px) {
  .company {
    padding: 60px 4% 60px;
    h4  {
      padding-top: 30px;
    }
    p {
      padding-top: 14px;
      br {
        display: block;
      }
    }
    table {
      margin-top: 40px;
      tr {
        th {
          width: 100%;
          display: block;
          padding: 10px 14px;
        }
        td {
          width: 100%;
          display: block;
          padding: 22px 14px;
        }
        &:first-of-type {
          td {
            border-top:none;
          }
        }
      }
    }
  }
}
.signature {
  position: relative;
  svg {
    width: 300px;
    display: none;
  }
  &::after {
    opacity: 0;
  }
  &.appear {
    svg {
        opacity: 1;
        display: block;
        animation: signature 6.9s ease 0s 1 forwards;
        position: relative;
        top: 2px;
    }
    &::after {
      content: "板野 知善";
      animation: appear 3s ease 5.7s 1 forwards;
      position: absolute;
      left: 0;
      top: 50%;
      line-height: 0;
    }
  }
}
@media screen\0 {
  .signature svg {display: none !important;}
}
@keyframes signature {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.st0{display:none;}
.st1{fill:none; stroke:#000; stroke-dasharray:4000; stroke-dashoffset: 4000; animation: signline1 8s ease-in-out alternate 0s forwards; stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
.st2{opacity: 0; fill:none; stroke:#000; stroke-dasharray:4000; stroke-dashoffset: 4000; animation: signline2 0.2s ease-in-out alternate 2.5s forwards; stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
.st3{fill:none; stroke:#000; stroke-dasharray:4000; stroke-dashoffset: 4000; animation: signline1 10s ease-out alternate 2.8s forwards; stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
.st4 {fill:none; stroke:#000; stroke-dasharray:4000; stroke-dashoffset: 4000; animation: signline1 5s ease-out alternate 3.7s forwards; stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
@keyframes signline1 {
    0% {
        stroke-dashoffset: 4000;
        }
    100% {
        stroke-dashoffset: 0;
        }
}
@keyframes signline2 {
    0% {
        stroke-dashoffset: 4000;
		opacity: 0;
        }
    100% {
        stroke-dashoffset: 0;
		opacity: 1;
        }
}
@keyframes signline3 {
    0% {
        stroke-dashoffset: 4000;
		opacity: 0;
        }
    100% {
        stroke-dashoffset: 0;
		opacity: 1;
        }
}
@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>