<script>
import ScrollyVideo from '../../node_modules/scrolly-video/dist/ScrollyVideo.js';

export default {
  props: {
    src: {
      type: String,
      required: true,
    },
    preload: {
      type: String,
      default: 'auto' // デフォルトをautoに
    }
  },
  data() {
    return {
      // variable to hold the scrollyVideo object
      scrollyVideo: null,

      // Store the props so we know when things change
      lastPropsString: '',
    };
  },
  methods: {
    refreshScrollyVideo(props) {
      // if scrollyvideo already exists and any parameter is updated, destroy and recreate.
      if (this.scrollyVideo) this.scrollyVideo.destroy();
      this.scrollyVideo = new ScrollyVideo({
        scrollyVideoContainer: this.$refs.containerElement,
        src: this.src, // 追加したプロパティ `src` を指定
        ...props,
      });
    },
    setVideoPercentage(...args) {
      if (this.scrollyVideo) this.scrollyVideo.setVideoPercentage(...args);
    },
  },
  watch: {
    $attrs: {
      handler: function () {
        // separate out the videoPercentage prop
        const { videoPercentage, ...restProps } = this.$attrs;

        if (JSON.stringify(restProps) !== this.lastPropsString) {
          this.refreshScrollyVideo(restProps);

          // Save the new props
          this.lastPropsString = JSON.stringify(restProps);
        }

        // If we need to update the target time percent
        if (
          this.scrollyVideo &&
          typeof videoPercentage === 'number' &&
          videoPercentage >= 0 &&
          videoPercentage <= 1
        ) {
          this.scrollyVideo.setVideoPercentage(videoPercentage);
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.refreshScrollyVideo(this.$attrs);
  },
  unmounted() {
    if (this.scrollyVideo) this.scrollyVideo.destroy();
  },
};
</script>

<template>
  <div ref="containerElement" data-scrolly-container />
  <section>
    <h2 class="heading"><em>EXAMPLE <span>of</span></em>STRONG POINT</h2>
    <strong><span class="icon"><v-icon icon="mdi-check-all" /></span><span class="txt">企画・制作・運用・改善までフルサポート</span></strong>
    <strong><span class="icon"><v-icon icon="mdi-check-all" /></span><span class="txt">広告・WEB制作の分野で15年以上の経験</span></strong>
    <strong><span class="icon"><v-icon icon="mdi-check-all" /></span><span class="txt">オーダーメードから早い・安いまでフレキシブルな対応</span></strong>
    <strong><span class="icon"><v-icon icon="mdi-check-all" /></span><span class="txt">コンバージョンをイメージした導線設計</span></strong>
    <strong><span class="icon"><v-icon icon="mdi-check-all" /></span><span class="txt">よい意味で下請けのような柔軟な対応</span></strong>
    <strong><span class="icon"><v-icon icon="mdi-check-all" /></span><span class="txt">ワンオペ & 共創型<sup>※</sup>でフレキシブルな課題解決</span></strong>
    <strong><span class="icon"><v-icon icon="mdi-check-all" /></span><span class="txt">仕様の開示で他社でも扱いやすい成果物</span></strong>
    <strong><span class="icon"><v-icon icon="mdi-check-all" /></span><span class="txt">著作権譲渡で改変も思いのまま</span></strong>
    <span class="andmore">..and more</span>
    <figure>
      <v-img :src="require('../assets/d7028261-8f49-47df-8491-2f20cde27a07.png')" :lazy="false" :loading="'eager'" />
      <v-img :src="require('../assets/fc348b10-c27e-4018-bd22-d2d6c308f19c.png')" :lazy="false" :loading="'eager'" />
    </figure>
    <span class="caution">※提供サービスは可能な限り内製化をモットーとしておりますが、<br>
極端な短納期や大きな課題には信頼のおけるパートナーとの共創で解決します。</span>
  </section>
</template>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bad+Script&display=swap');
  section {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    align-content: center;
    height: 100%;
    width: 70%;
    box-sizing: border-box;
    padding-left: 15%;
    z-index: 9999;
    position: absolute;
    top: 0;
    left: 0;
    .heading {
              display: block;
              width: 100%;
              font-size: unquote("clamp(3rem, 0.091rem + 7.27vw, 5rem)");
              left: 0;
              font-family: 'Montserrat', sans-serif;
              overflow: hidden;
              padding-bottom:1rem ;
              box-sizing: border-box;
              width: 100vw;
          }
          em {
            display: block;
            font-style: normal;
            span {
              font-family: "Bad Script", cursive;
              font-weight: 400;
              font-style: normal;
              color: gray;
            }
          }
    strong {
      font-size: unquote("clamp(1rem, 0.709rem + 0.73vw, 1.2rem)");
      padding-top: 2rem;
      width: 100%;
      display: flex;
      .icon {
        color: lightgray;
        padding-right: 0.6rem;
        
      }
      .txt {
        color: #fff;
      }
      &:last-of-type {
        padding-bottom: 1rem;
      }
    }
    .andmore {
      display: block;
      text-align: center;
      width: 100%;
      color:lightgray;
      // padding-top: 1rem;
    }
    .caution {
      display: block;
      padding-top: 70px;
      font-size: smaller;
      color: #fff;
      width: 100%;
    }
    figure {
      display: flex;
      justify-content: center;
      width: auto;
      padding: 40px 20px 0;
      gap:30px;
      ::v-deep div {
          width: 90px;
          height: 90px;
          border-radius: 50%;
        .v-responsive__sizer {
          padding-bottom: 90px !important;
        }
        img {
          // width: 90px;
          // height: 90px;
          transform: scale(1.1);
          position: relative; 
          border-radius: 50%;
          object-fit: cover;
        }
        &:nth-of-type(2) {
          padding-right: 0;
        }
      }
    }
    @media (max-width: 1290px) {
      width: 100%;
    }
    @media (max-width: 700px) {
      padding: 0 4%;
      figure {
        margin: 0 auto;
      }
      .caution {
        br {
          display: none;
        }
      }
    }
  }
</style>