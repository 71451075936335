<template>
  <v-app>
    <!-- <header-parts/> -->
    <v-main>
      <router-view/>
    </v-main>
    <footer-parts/>
    <div id="animated-background" class="animated-background animated-background--red" data-color="red">
      <canvas id="can0"></canvas>
    </div>
  </v-app>
</template>


<script>

import FooterParts from './global/FooterParts.vue'
// import HeaderParts from './global/HeaderParts.vue'

import jQuery from '../node_modules/jquery/dist/jquery.min.js';
global.jquery = jQuery
global.$ = jQuery
window.$ = window.jQuery = require('jquery')

export default {
  components: { FooterParts },
  name: 'App',

  data: () => ({
    //
  }),

  mounted() {
  (function ($) {
    $(document).ready(function() {
      $('.animated-background').each(function( index ) {
        var cnv = $("<canvas></canvas>").attr("id", "can"+index);
        let canvas = document.getElementById('can0');
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;
        let colorRange, strokeColor;

        var colorToUse = $(this).attr('data-color');
        if (colorToUse === 'white') {
          colorRange = ['rgba(227,227, 227, 0)', 'rgba(193, 23, 43, 0)'];
          strokeColor = 'rgba(224, 224, 224, 1)';
        } else {
          colorRange = ['rgba(245, 245, 245, alp)', 'rgba(229, 229, 229, alp)'];
          strokeColor = 'rgba(245,245,245, 0.5)';
        }

        $(this).prepend(cnv);

        var can = document.getElementById("can"+index);
        var w = can.width = $(this).width(),
        h = can.height = $(this).height(),
        ctx = can.getContext('2d'),

        opts = {
          side: 16,
          picksParTick: 8,
          baseTime: 200,
          addedTime: 5,
          colors: colorRange,
          addedAlpha: 1,
          strokeColor: strokeColor,
          hueSpeed: .1,
          repaintAlpha: 1
        },

        difX = Math.sqrt(3) * opts.side / 2,
        difY = opts.side * 3 / 2,
        rad = Math.PI / 6,
        cos = Math.cos(rad) * opts.side,
        sin = Math.sin(rad) * opts.side,

        hexs = []

        function loop() {
          window.requestAnimationFrame(loop);


          ctx.shadowBlur = 0;

          var backColor;
          if (colorToUse === 'white') {
            backColor = 'rgba(255, 255, 255, 1)';
          }
          else {
            backColor = 'rgba(255, 255, 255, 1)';
          }
          ctx.fillStyle = backColor.replace('alp', opts.repaintAlpha);
          ctx.fillRect(0, 0, w, h);

          for (var i = 0; i < opts.picksParTick; ++i)
            hexs[(Math.random() * hexs.length) | 0].pick();

          hexs.map(function(hex) {
            hex.step();
          });
        }

        function Hex(x, y) {
          this.x = x;
          this.y = y;
          this.sum = this.x + this.y;
          this.picked = false;
          this.time = 0;
          this.targetTime = 0;

          this.xs = [this.x + cos, this.x, this.x - cos, this.x - cos, this.x, this.x + cos];
          this.ys = [this.y - sin, this.y - opts.side, this.y - sin, this.y + sin, this.y + opts.side, this.y + sin];
        }

        Hex.prototype.pick = function() {
          this.color = opts.colors[(Math.random() * opts.colors.length) | 0];
          this.picked = true;
          this.time = this.time || 0;
          this.targetTime = this.targetTime || (opts.baseTime + opts.addedTime * Math.random()) | 0;
        }

        Hex.prototype.step = function() {
          var prop = this.time / this.targetTime;

          ctx.beginPath();
          ctx.moveTo(this.xs[0], this.ys[0]);
          for (var i = 1; i < this.xs.length; ++i)
            ctx.lineTo(this.xs[i], this.ys[i]);
          ctx.lineTo(this.xs[0], this.ys[0]);

          if (this.picked) {
            ++this.time;

            if (this.time >= this.targetTime) {
              this.time = 0;
              this.targetTime = 0;
              this.picked = false;
            }

            ctx.fillStyle = ctx.shadowColor = this.color.replace('alp', Math.sin(prop * Math.PI));
            ctx.fill();
          } else {
            ctx.strokeStyle = ctx.shadowColor = opts.strokeColor;
            ctx.stroke();
          }
        }

        for (var x = 0; x < w; x += difX * 2) {
          var i = 0;

          for (var y = 0; y < h; y += difY) {
            ++i;
            hexs.push(new Hex(x + difX * (i % 2), y));
          }
        }
        loop();

        window.addEventListener('resize', function() {
          w = can.width = window.innerWidth;
          h = can.height = window.innerHeight;

          if (can.width < window.innerWidth) {
            can.alpha = 0.5;
            can.opacity = 0.5;
          }

          hexs.length = 0;
          for (var x = 0; x < w; x += difX * 2) {
            var i = 0;

            for (var y = 0; y < h; y += difY) {
              ++i;
              hexs.push(new Hex(x + difX * (i % 2), y));
            }
          }
        });
      });
    });
  })(jQuery);
  }
}
</script>


<style lang="scss" scoped>
#app {
  position: relative;
  #animated-background {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 0;
  }
}
</style>
