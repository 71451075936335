<template>
  <div>
    404<br>
    <button @click="goToHome">back to TOP</button>
  </div>
</template>

<script>
export default {
    methods: {
        goToHome(){
            this.$router.push('/')
        }
    }
}
</script>

<style>

</style>