<template>

  <div class="header">
    <div class="spinner"></div>
    <router-link to="/contact"><v-icon icon="mdi-email-outline" /></router-link>
  </div>

  <div class="fv">
    <h1>ロープライス<span>で</span>ハイクオリティ<span>な</span><br>WEBソリューションプロバイダー<strong data-text="_01d">_01d</strong></h1>
    <a id="anchor" href="#" @click.prevent="warp"></a>
  </div>

  <section class="hidden_copy">
    <h2>尾道市か鞆の浦の古民家を改装した事務所でお洒落に働きたい！</h2>
    <p>私が独立をした理由の1つに
歴史と風情が息づく風光明媚で知られる広島県の尾道市か鞆の浦に事務所を構えて気持ち良く仕事をしたいと思ったからです。</p>
    <p>なんて小さな動機なんだ、、と思われる方もいらっしゃるかもしれません。
それでもいいのです。自分が一番よいパフォーマンスを発揮できるなら。</p>
    <p><strong>【あまりご存じない方のために】</strong><br>広島県の尾道市と鞆の浦は、どちらも日本が誇る絶景と歴史的な風情を併せ持つ観光地として人気を集めています。</p>
    <p>まず、尾道市は「坂の街」として知られ、狭い路地に沿った石畳の道や、古い町家が点在する風景が独特の魅力を放ちます。坂道を上ると、尾道水道と美しい瀬戸内海の島々が広がり、まるで絵画のような景色が一望できます。文学や映画の舞台にもなっており、訪れる人々はその静かな空気感に癒されます。また、尾道ラーメンや新鮮な海の幸など、地元ならではのグルメも見逃せません。</p>
    <p>一方、尾道市からほど近い鞆の浦は、江戸時代から港町として栄え、今もその時代の風情を色濃く残しています。古い町並みや伝統的な家屋が立ち並び、まるでタイムスリップしたかのような気分に浸れる場所です。瀬戸内海に面したこの港町では、潮の香りを感じながらのんびりと散策することができ、心が安らぐひとときを過ごせます。さらに、鞆の浦は数々の映画やアニメの舞台にも選ばれており、ファンにとっても見どころ満載です。</p>
    <p>どちらも、歴史と自然が調和した絶好のロケーションで、観光だけでなく、ゆっくりとした時間を過ごすにはぴったりの場所です。
尾道と鞆の浦を訪れることで、日常の喧騒から離れ、古き良き日本の魅力に触れる特別な体験ができるでしょう。</p>
    <p>とにかくそんな所で働きたいんです。。</p>
  </section>

  <div id="putiwarp" class="scrolly-pallax-container">
    <ScrollyPallax />
  </div>

  <div class="scrolly-service-container">
    <ScrollyService />
  </div>

  <div class="scrolly-video-container">
    <!-- <ScrollyComponent :src="require('../assets/chair1030_min.mp4')" preload="auto" /> -->
    <ScrollyComponent src="/assets/chair1030_min.mp4" preload="auto" />
  </div>

  <div id="company">
    <AboutCont />
  </div>
  
</template>

<script>
import { defineComponent } from 'vue';

// Components
import ScrollyComponent from '../components/ScrollyComponent.vue';
import ScrollyPallax from '../components/ScrollyPallax.vue';
import ScrollyService from '../components/ScrollyService.vue';
import AboutCont from '../components/AboutCont.vue';



export default defineComponent({
  name: 'HomeView',

  components: {
    ScrollyComponent,
    ScrollyPallax,
    ScrollyService,
    AboutCont,
  },
  data() {
    return {

    }
  },
  methods: {
 
    warp() {
            document.getElementById('putiwarp').scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
          }

  }
});
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@900&display=swap');
$border-width: 4px;
$basic-spinner-dimensions: 240px;
$main-spinner-dimensions: $basic-spinner-dimensions - $border-width * 2;
.header {
  text-align: right;
  width: 100%;
  overflow: hidden;
  position: relative;
  z-index: 2;
  .spinner {
    position: relative;
    width: $basic-spinner-dimensions;
    height: $basic-spinner-dimensions;
    right: -30px;
    margin-top: -20px;
    display: inline-block;
    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
      border-width: 4px;
      border-style: solid;
      border-radius: 50%;
    }
    @keyframes scale-2 {
      0% {
        transform: scale(0);
        opacity: 0;
      }
      
      50% {
        transform: scale(0.7);
        opacity: 1;
      }
      
      100% {
        transform: scale(1);
        opacity: 0;
      }
    }
    
    &:before {
      width: $main-spinner-dimensions;
      height: $main-spinner-dimensions;
      border-color: #212121;
      top: 0px;
      left: 0px;
      animation: scale-2 1.2s linear 0s infinite;
    }
    
    &:after {
      width: $main-spinner-dimensions;
      height: $main-spinner-dimensions;
      border-color: #212121;
      top: 0;
      left: 0;
      opacity: 0;
      animation: scale-2 1.2s linear 0.5s infinite;
    }
  }
  a {
    position: absolute;
    text-align: center;
    right: 72px;
    top: 70px;
    z-index: 3;
    font-size: 30px;
    color: #000;
  }
}
@media (max-width: 700px) {
    $basic-spinner-dimensions: 140px;
    $main-spinner-dimensions: $basic-spinner-dimensions - $border-width * 2;
    .header {
      .spinner {
        width: $basic-spinner-dimensions;
        height: $basic-spinner-dimensions;
        &:before {
          width: $main-spinner-dimensions;
          height: $main-spinner-dimensions;
        }
        &:after {
          width: $main-spinner-dimensions;
          height: $main-spinner-dimensions;
        }
      }
      a {
        right: 26px;
        top: 28px;
        font-size: 24px;
      }
    }
    .hidden_copy {
      padding: 0% 4%;
      height: 200px;
      overflow-y: scroll;
    }
}

.fv {
  text-align: center;
  padding: 0 4% 0;
  box-sizing: border-box;
  width: 100%;
  height: 100vh;
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  z-index: 1;
  overflow: hidden;
    #anchor {
      position: absolute;
      left: 50%;
      bottom: 0;
      z-index: 1;
      width: 200px;
      height: 80px;
      margin-left: -100px;
      
    &::before {
      width: 2px;
      height: 80px;
      background: gray;
      animation: scrollDown 2s ease infinite;
      content: "";
      display: block;
      position: absolute;
      left: 80px;
      bottom: -20px;
    }
    &::after {
      width: 2px;
      height: 80px;
      background: gray;
      animation: scrollDown 2s ease 0.2s infinite;
      content: "";
      display: block;
      position: absolute;
      right: 80px;
      top: -20px;
    }
    }

  @keyframes glitch-it {
    0% {
        transform: translate(0);
    }
    20% {
        transform: translate(-2px, 2px);
    }
    40% {
        transform: translate(-2px, -2px);
    }
    60% {
        transform: translate(2px, 2px);
    }
    80% {
        transform: translate(2px, -2px);
    }
    to {
        transform: translate(0);
    }
  }
  h1 {
    font-size: unquote("clamp(0.875rem, 0.693rem + 0.45vw, 1rem)");
    font-weight: normal;
    margin-top: -10%;
    position: relative;
    span {
      font-size: smaller;
    }
    strong {
      font-family: 'Montserrat', sans-serif;
      font-size: unquote("clamp(6rem, 1.636rem + 10.91vw, 9rem)");
      display: inline-block;
      line-height: 1;
      position:relative;
      &::before, &::after {
        display: block;
        content: attr(data-text);
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.8;
      }
      &::before {
        animation: glitch-it 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both infinite;
        color: #00FFFF;
        z-index: -1;
      }
      &::after {
        animation: glitch-it 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) reverse both infinite;
        color: #FF00FF;
        z-index: -2;
      }
      

    }
    &::after {
        content: "アンダーバーオールド";
        position:absolute;
        right:0;
        bottom:0;
        color:#b3b3b3;
        font-family: 'Montserrat', sans-serif;
        font-size: unquote("clamp(1.125rem, 0.216rem + 2.27vw, 1.75rem)");
        mix-blend-mode: exclusion;
        line-height: 1;
        font-weight: bold;
      }
  }
}
@media (max-width: 768px) {
    .fv {
      height: 100svh;
    }
}
@media (max-width: 464px) {
  .fv {
    h1 {
      &::after {
        left: 0;
        width: 100%;
        margin-left: 20px;
        right: auto;
      }
    }
  }
}
.hidden_copy {
  padding: 10% 4%;
  text-align: center;
  h2,p {
    color: #fff;
    position: relative;
    z-index: -1;
  }
}
.scrolly-pallax-container {
  background:#fff;
}
.scrolly-video-container {
  height: 200vh;
  position: relative;
  z-index: 1;
  will-change: transform;
  &::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.6);
    z-index: 1;
  }
}

.v-main {
  padding-top: 0 !important;
  --v-layout-top: 0 !important;
}
.scrolly-service-container {
  position: relative;
  z-index: 3;
}

@keyframes scrollDown{
    0%{
        transform-origin: top;
        transform: scaleY(0);
    }
    45%{
        transform-origin: top;
        transform: scaleY(1);
    }
    55%{
        transform-origin: bottom;
        transform: scaleY(1);
    }
    100%{
        transform-origin: bottom;
        transform: scaleY(0);
    }
}


</style>
