<template>
  <footer>
    <div class="scroll-infinity">
      <div class="scroll-infinity__wrap">
        <ul class="scroll-infinity__list scroll-infinity__list--left">
          <li class="scroll-infinity__item"><v-img :src="require('../assets/footer_slide_bg.png')"/></li>
          <li class="scroll-infinity__item"><v-img :src="require('../assets/footer_slide_bg.png')"/></li>
          <li class="scroll-infinity__item"><v-img :src="require('../assets/footer_slide_bg.png')"/></li>
          <li class="scroll-infinity__item"><v-img :src="require('../assets/footer_slide_bg.png')"/></li>
          <li class="scroll-infinity__item"><v-img :src="require('../assets/footer_slide_bg.png')"/></li>
        </ul>
        <ul class="scroll-infinity__list scroll-infinity__list--left">
          <li class="scroll-infinity__item"><v-img :src="require('../assets/footer_slide_bg.png')"/></li>
          <li class="scroll-infinity__item"><v-img :src="require('../assets/footer_slide_bg.png')"/></li>
          <li class="scroll-infinity__item"><v-img :src="require('../assets/footer_slide_bg.png')"/></li>
          <li class="scroll-infinity__item"><v-img :src="require('../assets/footer_slide_bg.png')"/></li>
          <li class="scroll-infinity__item"><v-img :src="require('../assets/footer_slide_bg.png')"/></li>
        </ul>
        <ul>
          <li><v-img :src="require('../assets/footer_slide_bg.png')"/></li>
        </ul>
      </div>
    </div>
    <span class="copy">&copy;&nbsp;_01d</span>
    <span class="caution">当サイトはGoogleの<a target="_blank" href="https://policies.google.com/privacy?hl=ja">プライバシーポリシー</a>と<a target="_blank" href="https://policies.google.com/terms?hl=ja">利用規約</a>が適応されます。</span>
  </footer>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
    @keyframes infinity-scroll-left {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }
  footer {
    position: relative;
    z-index: 1;
    .copy {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background:rgba(255, 255, 255, 0.9);
    }
    .caution {
      font-size: smaller;
      position:absolute;
      width: 100%;
      text-align: center;
      left: 0;
      bottom: 0;
      color: gray;
      a {
        color: gray;
        &:hover {
          text-decoration: none;
        }
      }
    }
  .scroll-infinity__wrap {
    display: flex;
    overflow: hidden;
      .scroll-infinity__list {
        display: flex;
        list-style: none;
        padding: 0;
        &.scroll-infinity__list--left {
          animation: infinity-scroll-left 80s infinite linear 0.5s both;
        }
        .scroll-infinity__item {
          width: calc(100vw / 2);
          img {
            width: 100%;
          }
        }
      }
    }
    @media (max-width: 700px) {
      .scroll-infinity__wrap {
        .scroll-infinity__list {
          .scroll-infinity__item {
            width: calc(100vw / 1.4);
          }
        }
      }
      .copy {
        top: 20%;
      }
    }
  }
    
</style>