<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger)

export default {
  mounted() {
    gsap.fromTo(
      '.layer',
      {
        '--clip': '100%',

      },
      {
        '--clip': '0%',


        scrollTrigger: {
          trigger: '.parallax-element', // トリガーとなる要素（アニメーションの開始、終了の位置決定に使用する）
          start: 'top 70%', // アニメーションの開始位置の指定
          scrub: 1, // スクロール量に応じて
          toggleActions: "play none none reverse",
          markers: false,
          },
          stagger: {
            from: "start", //start = 要素順にアニメーション。 start、center、edges、random、endで指定可能
            each:0.2, // それぞれを0.4秒ずつずらしてアニメーション
          },
      }
    );


  },
};
</script>

<template>

    <div class="parallax-container">
        <section class="parallax-element cont1 layer">
          <div class="pe_inner"><h2 class="heading"><span>G</span>REETING</h2>
          <!-- <p>アンダーバーオールドとは<br>
更新対象のファイルのバックアップを取る際に<br>
よく用いられていた命名ルールの１つです。</p> -->
<p>日進月歩の世界で、</p>
        </div>
        </section>
        <section class="parallax-element cont2 layer">
          <div class="pe_inner"><h2 class="heading">G<span>R</span>EETING</h2>
          <!-- <p>今では非効率とされているような技術や運用にも<br>
元々は意味があり、それらを理解した上で<br>
時代に沿った提案をすることで説得力があると考えます。</p> -->
<p>初心を忘れず、よりスマートに、、</p>
          </div>
        </section>
        <section class="parallax-element cont3 layer">
          <div class="pe_inner"><h2 class="heading">GR<span>E</span>ETING</h2>
          <p>そんな当たり前を大切にしていきます。</p>
          </div>
        </section>
    </div>

</template>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@900&display=swap');
  .parallax-container {
      position: relative;
      width: 100%;
      height: 180vh;
      
      .parallax-element {
          width: 100%;
          &.layer {
              --clip:100%;
              clip-path: inset(var(--clip) 0px 0px);
              position: relative;
          }
          &.cont1 {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              overflow: hidden;
              z-index: 1;
              background:#fff;
              span {
                color: gray;
              }
          }
          &.cont2 {
              @extend .cont1;
              z-index: 2;
              span {
                color: #115d10;
              }
          }
          &.cont3 {
              @extend .cont1;
              z-index: 3;
              // height: 130%;
              span {
                color: #c1a64e;
              }
          }
          .pe_inner {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            align-content: center;
            position: fixed;
            top:0;
            left: 0;
            height: 100vh;
            margin-top: -50px;
          }
          .heading {
              display: block;
              width: 100%;
              text-align: center;
              font-size: unquote("clamp(3rem, 0.091rem + 7.27vw, 5rem)");
              left: 0;
              font-family: 'Montserrat', sans-serif;
              overflow: hidden;
              padding: 0 5%;
              box-sizing: border-box;
              width: 100vw;
          }
          p {
            @extend .heading;
            // top:42%;
            font-size: unquote("clamp(1rem, 0.818rem + 0.45vw, 1.125rem)");
            line-height: 2;
            // padding-bottom: 5%;
          }
          @media (max-width: 640px) {
            .pe_inner {
              margin-top: 0;
              p {
              br {
                display: none;
              }
            }
            }
            }
      }
  }
</style>