<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
  mounted() {
    const container = document.querySelector(".sc");
    const items = document.querySelectorAll(".si");
    
    // コンテナとアイテムの幅を設定
    gsap.set(container, {
      width: items.length * 100 + "%"
    });
    gsap.set(items, {
      width: 100 / items.length + "%"
    });

    // ScrollTrigger設定（画面サイズに関係なく横スクロールを有効化）
    ScrollTrigger.matchMedia({
      // 全画面サイズに対して適用
      "all": () => {
        gsap.to(".si", {
          xPercent: -100 * (items.length - 1),
          scrollTrigger: {
            trigger: container,
            start: "center center",
            end: `+=${container.clientWidth}`, // コンテナの幅を使用
            pin: true,
            scrub: true,
            anticipatePin: 1,
          }
        });
      }
    });
  },
};
</script>

<template>

    <div class="of">
      <div class="sc">
        <section class="si">
          <figure><v-img :src="require('../assets/target1.svg')"/></figure>
          <div>
            <h3>販促支援</h3>
            <p>広告運用、ランディングページ作成、ABテスト、効果計測などトータルでご相談ください。<br>
            勿論、SEO対策・アクセス解析にも対応しております。</p>
          </div>
        </section>
        <section class="si">
          <figure><v-img :src="require('../assets/website.svg')"/></figure>
          <div>
            <h3>WEBサイト制作</h3>
            <p>リーズナブルでも力持ち。商材を引き立てるスッキリしたデザインが得意です。<br>
            特にWordPressサイトの実績は100サイト以上。更新性を高め、セキュリティやメンテナンスにも配慮しています。</p>
          </div>
        </section>
        <section class="si">
          <figure><v-img :src="require('../assets/drone.svg')"/></figure>
          <div>
            <h3>各種撮影</h3>
            <p>WEB素材用の写真・動画撮影を行ないます。空撮も対応可能です。<br>自社PRにご活用ください。</p>
          </div>
        </section>
        <section class="si">
          <figure><v-img :src="require('../assets/system.svg')"/></figure>
          <div>
            <h3>その他</h3>
            <p>システム開発、DX導入支援、CAD設計など幅広くご相談を受け付けております。<br>
            フレキシブルなサービスをモットーとしています。</p>
          </div>
        </section>
        <canvas id="canvas"></canvas>
      </div>
    </div>

</template>

<style lang="scss" scoped>
.of {
  overflow: hidden;
  .sc {
    display: flex;
    background:#000;
    position: relative;
    canvas {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
    }
    .si {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      padding: 260px 5%;
      overflow: hidden;
      position: relative;
      box-sizing: border-box;
      figure {
        width: 100px;
        height: 100px;
        // background:gray;
        * {
          width: 100% !important;
          height: 100% !important;
        }
      }
      div {
        width: calc(100% - 100px);
        box-sizing: border-box;
        padding-left: 40px;
        h3 {
        font-size: unquote("clamp(1.125rem, 0.034rem + 2.73vw, 1.875rem)");
        span {
          color: gray;
          padding: 0 4px;
        }
        }
        p {
          font-size: unquote("clamp(1rem, 0.818rem + 0.45vw, 1.125rem)");
          color: #cdcdcd;
          padding-top: 6px;
        }
      }
      
      &::after {
        content: '';
        display: block;
        position: absolute;
        height: 15vh;
        width: 2px;
        top: -50%;
        right: 0;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 75%, #ffffff 100%);
        animation: drop 3s 0s infinite;
        animation-fill-mode: forwards;
        animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
      }
      &:last-of-type {
        &::after {
          content: none;
        }
      }
      @media (max-width: 1000px) {
        padding: 260px 2%;
        div {
          p {
            br {
              display: none;
            }
          }
        }
      }
      @media (max-width: 640px) {
        display: block;
        figure {
          margin: 0 auto;
        }
        div {
          width: 100%;
          padding-left: 0;
          h3 {
            text-align: center;
          }
          p {
            padding-top: 20px;
          }
        }
      }
    }
    &::before {
      width: 100vw;
      height: 100%;
      position: absolute;
      content: "";
      opacity: 0.6;
      background: rgb(0,0,0);
      background: linear-gradient(158deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 53%, rgba(17,93,16,1) 87%, rgba(128,104,26,1) 96%);
      // background: linear-gradient(158deg, black 0%, black 90%, #00FFFF 99%);
      animation: grow 10s forwards ease infinite;
    }
  }
}
@keyframes drop {
  0% {
    top: -50%;
  }
  100% {
    top: 110%;
  }
}
</style>